.block-card
  &--without-border
    @apply border-0 #{!important}

  &--big-description
    .block-card__header
      @apply pt-4 #{!important}
      @apply pb-0 #{!important}

    .block-card__title
      @apply text-24 #{!important}

    .block-card__content
      @apply text-18 #{!important}
      @apply pb-4 #{!important}
      @apply pt-1 #{!important}
      @apply text-black

    .block-card__link
      @apply mt-2 #{!important}

  &__svg
    svg
      @apply h-[90px]
      @apply mx-auto

    path[fill]
      @apply fill-current #{!important}

    path[stroke],
    g[stroke]
      @apply stroke-current #{!important}

  &__card:last-child .block-card__arrow
    @apply hidden

  &--primary
    .block-card__header
      @apply bg-fluo-green-500
      @apply text-black

      path
        @apply text-black

  &--light
    .block-card__header
      @apply bg-transparent
      @apply text-black

      path
        @apply text-fluo-green-500

  &--gray
    @apply bg-gray-50

    .block-card__header
      @apply bg-transparent
      @apply text-black

      path
        @apply text-fluo-green-500
