.heading
  &__border::after
    content: ""
    @apply absolute
    @apply left-0
    @apply top-0
    @apply h-full
    @apply w-[10px]
    @apply rounded-full
    @apply bg-fluo-green-500
