.img-cover
  @apply h-full
  @apply w-full
  @apply object-cover
  font-family: 'object-fit: cover' // for the polyfill object-fit cover

.img-contain
  @apply h-full
  @apply w-full
  @apply object-contain
  font-family: 'object-fit: contain' // for the polyfill object-fit contain

.fix-width
  a
    @apply w-full
