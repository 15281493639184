.wysiwyg-wrapper
  @apply prose
  @apply prose-xl

  @apply text-base
  @apply leading-[30px]

  @apply text-gray-600
  @apply max-w-full
  @apply prose-headings:text-grey-900
  @apply prose-headings:mt-0

  @apply prose-h1:text-28
  @apply prose-h1:font-bold
  @apply prose-h1:leading-tight
  @apply prose-h1:mb-5

  @screen lg
    @apply prose-h1:text-30

  @apply prose-h2:text-22
  @apply prose-h2:font-bold
  @apply prose-h2:leading-tight
  @apply prose-h2:mb-3

  @screen lg
    @apply prose-h2:text-24

  @apply prose-h3:text-20
  @apply prose-h3:font-bold
  @apply prose-h3:leading-tight
  @apply prose-h3:mb-0

  @screen lg
    @apply prose-h3:text-24

  @apply prose-h4:text-18
  @apply prose-h4:mb-0
  @apply prose-h5:text-base

  @apply prose-h6:text-base
  @apply prose-h6:font-bold

  @apply prose-p:mt-0
  @apply prose-p:text-base
  @apply prose-p:leading-[24px]
  @apply prose-p:mb-1

  @apply prose-li:leading-[24px]
  @apply prose-li:mb-1
  @apply prose-li:mt-1

  @apply prose-ul:my-1

  @apply prose-blockquote:text-20
  @apply prose-blockquote:font-bold
  @apply prose-blockquote:leading-snug
  @apply prose-blockquote:border-primary-500
  @apply prose-blockquote:text-grey-900
  @apply prose-blockquote:leading-[31px]
  @apply prose-blockquote:border-none
  @apply prose-blockquote:pl-0
  @apply prose-blockquote:flex
  @apply prose-blockquote:not-italic
  @apply prose-blockquote:mb-2
  @apply prose-blockquote:mt-4

  @apply prose-img:my-3

  @apply prose-a:transition-colors
  @apply prose-a:ease-smooth
  @apply prose-a:duration-smooth
  @apply prose-a:font-light
  @apply prose-a:border-none

  p:last-of-type,
  ul:last-of-type,
  img:last-of-type
  blockquote:last-of-type
    @apply mb-0

  figure[style*="text-align"]
    img
      @apply mx-auto

  blockquote::before
    content: ""
    @apply block
    @apply min-w-[32px]
    @apply h-[24px]
    @apply mr-5
    @apply bg-cover
    @apply bg-no-repeat
    background-image: url('../images/content-pages/green-quote.svg')

    @screen lg
      @apply min-w-[58px]
      @apply h-[43px]
      @apply mr-7

  ul
    ::marker
      @apply text-grey-600

.wysiwyg-wrapper--dark
  @apply text-white
  @apply prose-headings:text-white
  @apply prose-blockquote:border-white
  @apply prose-blockquote:text-white
  @apply prose-a:text-white

  blockquote::before
    background-image: url('../images/content-pages/white-quote.svg')

  strong
    @apply text-white

  ul
    ::marker
      @apply text-white

.wysiwyg-wrapper--primary
  @apply text-grey-900

  blockquote::before
    background-image: url('../images/content-pages/white-quote.svg')
