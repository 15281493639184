.block-text-image
  &--left
    .block-text-image__text
      @apply order-last

    .block-text-image__image
      @apply order-first

  &--right
    .block-text-image__text
      @apply order-first

    .block-text-image__image
      @apply order-last

  &--primary
    @apply bg-fluo-green-500
    @apply text-grey-900

    .block-text-image__button
      @apply border-grey-900
      @apply text-grey-900

  &--dark
    @apply bg-primary-500
    @apply text-white

    .block-text-image__button
      @apply border-white
      @apply text-white

      &:hover
        @apply bg-white
        @apply text-grey-900

  &--light
    h1, h2, h3, h4, h5, h6
      @apply text-black

    .block-text-image__button
      @apply border-grey-900
      @apply text-grey-900

  &--gray
    @apply bg-gray-50

    h1, h2, h3, h4, h5, h6
      @apply text-black

    .block-text-image__button
      @apply border-grey-900
      @apply text-grey-900
