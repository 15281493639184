.block-heading
  &--primary
    @apply bg-fluo-green-500
    @apply text-grey-900

    .block-heading__border::after
      @apply bg-white

    h1, h2, h3, h4, h5, h6
      @apply text-grey-900

    .block-heading__button
      @apply border-grey-900
      @apply text-grey-900

  &--dark
    @apply bg-primary-500
    @apply text-white

    .block-heading__border::after
      @apply bg-white

    .block-heading__button
      @apply border-white
      @apply text-white

      &:hover
        @apply bg-white
        @apply text-grey-900

  &--light
    .block-heading__border::after
      @apply bg-fluo-green-500

    h1, h2, h3, h4, h5, h6
      @apply text-black

    .block-heading__button
      @apply border-grey-900
      @apply text-grey-900

  &--gray
    @apply bg-gray-50

    .block-heading__border::after
      @apply bg-fluo-green-500

    h1, h2, h3, h4, h5, h6
      @apply text-black

    .block-heading__button
      @apply border-grey-900
      @apply text-grey-900

.block-heading
  &__border::after
    content: ""
    @apply absolute
    @apply left-0
    @apply top-0
    @apply h-full
    @apply w-[10px]
    @apply rounded-full
