$path: '../fonts/montserrat/'

@mixin declare-font-face($fontFileName, $fontFamilyName, $fontWeight: normal, $fontStyle: normal, $SVGName: '')
  @font-face
    font-family: $fontFamilyName
    font-weight: $fontWeight
    font-style: $fontStyle
    font-display: swap

    src: url('#{$path}#{$fontFileName}.woff2') format('woff2'), url('#{$path}#{$fontFileName}.woff') format('woff')

@include declare-font-face('Montserrat-Black', 'Montserrat', 900, normal)
@include declare-font-face('Montserrat-Bold', 'Montserrat', 700, normal)
@include declare-font-face('Montserrat-SemiBold', 'Montserrat', 600, normal)
@include declare-font-face('Montserrat-Medium', 'Montserrat', 500, normal)
@include declare-font-face('Montserrat-Regular', 'Montserrat', 400, normal)
@include declare-font-face('Montserrat-Light', 'Montserrat', 300, normal)

