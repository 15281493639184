.block-catchphrase
  &--primary
    @apply bg-fluo-green-500
    @apply text-grey-900

    .block-catchphrase__button
      @apply border-grey-900
      @apply text-grey-900

    path
      @apply text-white

  &--dark
    @apply bg-primary-500
    @apply text-white

    path
      @apply text-white

  &--light
    @apply bg-white

    .block-catchphrase__button
      @apply border-grey-900
      @apply text-grey-900

    .block-catchphrase__author
      @apply text-grey-500

    path
      @apply text-secondary-500

  &--gray
    @apply bg-gray-50

    .block-catchphrase__button
      @apply border-grey-900
      @apply text-grey-900

    .block-catchphrase__author
      @apply text-grey-500

    path
      @apply text-secondary-500
