.burger
  &::after
    content: '\f0c9'

  &.active::after
    content: '\f00d'

.burger-white
  @apply text-white

  &.active
    @apply text-grey-500
