// Retracted style on scroll
.header-is-scrolling
  .header-desktop
    @apply h-[80px]
    @apply shadow-lg

    &__container
      @apply py-2

    &__logo
      @apply h-[32px]

    &__links
      @apply pt-2

    &__user
      @apply h-[45px]
      @apply w-[45px]

      @screen xl
        @apply w-auto
        @apply px-3

  .header-mobile
    @apply h-16
    @apply shadow-lg
    @apply bg-white

// Submenu on hover
.submenu
  $current: &

  &:hover
    .header-desktop__link
      > div
        @apply opacity-100

    #{$current}__overlay
      @apply opacity-100
      @apply visible
      @apply translate-y-full
      @apply pointer-events-auto

// Active state
.header-desktop__link.active
  @apply text-fluo-green-500

  div
    @apply opacity-100

.header-mobile__link.active
  @apply text-fluo-green-500
