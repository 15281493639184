////////////////////
// LIST
////////////////////////////////////////////////////////////////////////////////

@mixin list($bullet-color: 'bg-primary-500')
  @apply space-y-3

  li
    @apply relative
    @apply pl-6

  li:last-child
    @apply mb-0

  li::before
    content: ' '
    @apply absolute
    @apply left-0
    @apply top-0
    @apply inline-block
    min-width: 8px
    min-height: 8px
    margin-top: 0.5rem
    @apply rounded-full
    @apply #{$bullet-color}
    @apply self-start

.list
  @include list
