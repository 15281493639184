////////////////////
// ANIMATION
////////////////////////////////////////////////////////////////////////////////


// ANIM SOLO
// ---------------------------------------------------------
.anim-fade
  @apply opacity-0
  @apply transition
  @apply transition-opacity
  @apply duration-smooth
  @apply ease-smooth

.anim-up
  @apply transition
  @apply transition-transform
  @apply duration-smooth
  @apply ease-smooth

.anim-fade-up
  @apply opacity-0
  transform: translate3d(0,10%,0)
  @apply transition
  @apply transition-animation
  @apply duration-smooth
  @apply ease-smooth

////////////////////
// ANIMATION IN
////////////////////////////////////////////////////////////////////////////////
.in

  &.anim-fade,
  .anim-fade
    @apply opacity-100

  &.anim-up,
  .anim-up
    transform: translate3d(0,0,0)

  &.anim-fade-up,
  .anim-fade-up
    @apply opacity-100
    transform: translate3d(0,0,0)


// CASCAD
// ---------------------------------------------------------
.anim--cascad
  .anim-fade-up,
  .anim-fade
    +transitionDelayUp(120ms, 120ms, 1, 20)
