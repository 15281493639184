.line-clamp-1
  @include lineClamp(1)

.line-clamp-2
  @include lineClamp(2)

.line-clamp-3
  @include lineClamp(3)

.picture-w-full
  @apply w-full

  picture
    @apply w-full
