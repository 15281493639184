// TRANSITIONS
// ---------------------------------------------------------

  ////////////////////
// FLASH MESSAGE
////////////////////////////////////////////////////////////////////////////////
.flash-message
  opacity: 0
  position: fixed
  right: 20px
  transition: opacity 350ms ease 0s, visibility 350ms ease 350ms
  top: 20px
  visibility: hidden
  z-index: 90

  &.show
    opacity: 1
    @apply transition
    @apply transition-opacity
    @apply duration-100
    visibility: visible

  ///// ALERT
  .alert
    box-shadow: 0 0 5px rgba(0,0,0,0.25)
    background: #fff
    border: 0
    border-radius: 2px
    font-size: 15px
    font-weight: normal
    max-width: 440px
    padding: 15px 25px 15px 15px
    text-align: left

  /// Alert danger
  .alert-danger
    background: #ff6363
    color: #fff

  /// Alert info
  .alert-info
    background: #62c5da
    color: #fff

  /// Alert success
  .alert-success
    background: #36d2a1
    color: #fff

  /// Alert wraning
  .alert-warning
    background: #ffaf57
    color: #fff


///// CLOSE
.flash-close
  color: #fff
  cursor: pointer
  position: absolute
  font-size: 20px
  right: 3px
  top: 0
  opacity: 0

.flash-message:hover .flash-close
  opacity: 1
