.video-wrapper
  iframe
    @apply absolute
    @apply w-screen
    @apply top-1/2
    @apply left-1/2
    height: 56.25vw
    min-height: 100vh
    min-width: 177.77vh
    transform: translate(-50%, -50%)

.in
  .video-wrapper__container
    @apply opacity-100
    transition-delay: 2s
