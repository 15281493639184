@mixin arrowSlider($id-icon: '\f053', $background: 'bg-transparent', $background-hover: 'bg-grey-500', $border-color: 'border-white', $text-color: 'text-white', $text-color-hover: 'text-white')
  @apply #{$background}
  @apply shadow
  @apply rounded-full
  @apply flex
  @apply items-center
  @apply justify-center
  @apply border
  @apply border-[2px]
  @apply #{$border-color}
  @apply h-[35px]
  @apply w-[35px]
  @apply transition-colors
  @apply ease-smooth
  @apply duration-smooth

  @screen lg
    @apply h-[66px]
    @apply w-[66px]

  &:hover
    @apply #{$background-hover}

    &::after
      @apply #{$text-color-hover} #{!important}

  &::after
    content: $id-icon
    @apply text-xs
    @apply #{$text-color}
    @apply font-icon
    @apply font-light
    @apply leading-none
    @apply transition-colors
    @apply ease-smooth
    @apply duration-smooth

@mixin bullet()
  @apply bg-transparent #{!important}
  @apply h-4 #{!important}
  @apply w-4 #{!important}
  @apply opacity-100 #{!important}
  @apply transform #{!important}
  @apply scale-100 #{!important}
  @apply border
  @apply border-white

  &-active
     @apply bg-white #{!important}

.slider-navigation
  .swiper-wrapper
    @apply relative
    @apply z-10
    @apply pb-8

  .swiper-pagination-bullet
    @include bullet()

  .swiper-button-prev
    @apply top-auto
    @apply bottom-2
    @include arrowSlider($id-icon: '\f053')

  .swiper-button-next
    @apply top-auto
    @apply bottom-2
    @include arrowSlider($id-icon: '\f054')

.slider
  .swiper-wrapper
    @apply relative
    @apply z-10

  .swiper-pagination
    @apply absolute
    @apply left-0
    @apply right-0
    @apply mx-auto
    @apply bottom-4
    @apply z-20

  .swiper-pagination-bullet
    @include bullet()

  .swiper-button-prev
    @include arrowSlider($id-icon: '\f053', $background: 'bg-transparent', $background-hover: 'bg-white', $border-color: 'border-white', $text-color: 'text-white', $text-color-hover: 'text-grey-900')

  .swiper-button-next
    @include arrowSlider($id-icon: '\f054', $background: 'bg-transparent', $background-hover: 'bg-white', $border-color: 'border-white', $text-color: 'text-white', $text-color-hover: 'text-grey-900')
