////////////////////
// FIREFOX
////////////////////////////////////////////////////////////////////////////////

///// FORM ERRORS
.form-input
  &:-moz-ui-invalid:-moz-focusring:not(output),
  &:-moz-ui-invalid:not(output)
    box-shadow: none
    border-color: var(--red)

///// REMOVE ARROW
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator
  display: none
  -webkit-appearance: none
  opacity: 0
  margin: 0

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type="number"]
  -moz-appearance: textfield

button
  @apply outline-none
