.block-kpi
  &--primary
    @apply bg-fluo-green-500
    @apply text-grey-900

  &--dark
    @apply bg-primary-500
    @apply text-white

  &--light
    @apply bg-white
    @apply text-grey-900

    .block-kpi__title
      @apply text-black

    .block-kpi__subtitle
      @apply text-black

    .block-kpi__value
      @apply text-primary-500

    .block-kpi__comment
      @apply text-grey-600

    .block-kpi__description
      @apply text-grey-300

  &--gray
    @apply bg-gray-50
    @apply text-grey-900

    .block-kpi__title
      @apply text-black

    .block-kpi__subtitle
      @apply text-black

    .block-kpi__value
      @apply text-primary-500

    .block-kpi__comment
      @apply text-grey-600

    .block-kpi__description
      @apply text-grey-300
