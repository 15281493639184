.btn
  @apply inline-flex
  @apply items-center
  @apply rounded-full
  @apply transition-colors
  @apply duration-500
  @apply ease-smooth
  @apply font-medium
  @apply cursor-pointer
  @apply text-base

  &:focus
    @apply outline-none
    @apply ring-2
    @apply ring-offset-2

.grecaptcha-badge
  @apply hidden #{!important}
