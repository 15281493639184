// Overflow hidden classes IOS 13
.overflow-hidden-html
  @apply static
  @apply overflow-y-hidden
  @apply h-full
  @apply max-h-full

.overflow-hidden-body
  @apply overflow-hidden
  @apply h-full
  @apply max-h-full
