.block-communication
  &--primary
    @apply bg-primary-500
    @apply text-white

  &--dark
    @apply bg-grey-50
    @apply text-primary-500

    .block-communication__subtitle
      @apply text-gray-600

  &--primary,
  &--dark
    .block-communication__card
      @apply bg-white

  &--light
    @apply bg-white
    @apply text-primary-500

    .block-communication__subtitle
      @apply text-gray-600

    .block-communication__card
      @apply bg-gray-50

  &--gray
    @apply bg-gray-50
    @apply text-primary-500

    .block-communication__subtitle
      @apply text-gray-600

    .block-communication__card
      @apply bg-gray-50
