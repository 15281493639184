.form
  @apply relative

  label
    @apply block
    @apply font-bold
    @apply mb-2

  input
    @apply h-[60px]

  input,
  select,
  textarea
    @apply relative
    @apply block
    @apply w-full
    @apply pl-4
    @apply pr-12
    @apply rounded-[3px]
    @apply text-grey-500
    @apply border
    @apply border-grey-50
    @apply placeholder-grey-500
    @apply transition-colors
    @apply duration-500
    @apply ease-smooth

    &:focus
      @apply border-grey-100
      @apply text-primary-500
      @apply placeholder-primary-500
      @apply ring-0
      @apply outline-none

    &:disabled
      @apply border-grey-100
      @apply bg-grey-100
      @apply placeholder-grey-300
      @apply text-grey-300
