.dropdown
  &__content
    transform: translate3d(0,95%,0)

  &.active
    .dropdown__content
      @apply translate-y-full
      @apply opacity-100
      @apply pointer-events-auto

    .dropdown__icon
      @apply rotate-180

.collapse
  &__btn
    &::after
      content: '\f078'
      @apply font-icon
      @apply text-10
      @apply text-white
      @apply h-[40px]
      @apply w-[40px]
      @apply rounded-full
      @apply bg-grey-600
      @apply flex
      @apply justify-center
      @apply items-center
      @apply min-w-[40px]
      @apply transition-colors
      @apply duration-smooth
      @apply ease-smooth
      @apply ml-2

  &__content
    @apply overflow-hidden
    max-height: 0px
    transition: max-height 400ms ease-in-out

  &__input:checked ~ .collapse__btn
    &::after
      content: '\f068'
      @apply bg-grey-300

  &__input:checked ~ .collapse__content
    @apply overflow-visible
    max-height: 9000px
    transition: max-height 400ms ease-in-out

.collapse-menu
  &__btn
    &::after
      content: '\f054'
      @apply font-icon
      @apply min-w-[20px]
      @apply transition-colors
      @apply duration-smooth
      @apply ease-smooth
      @apply ml-2

  &__content
    @apply overflow-hidden
    max-height: 0px
    transition: max-height 400ms ease-in-out

  &__input:checked ~ .collapse-menu__btn
    &::after
      content: '\f078'

  &__input:checked ~ .collapse-menu__content
    @apply overflow-visible
    max-height: 800px
    transition: max-height 400ms ease-in-out
