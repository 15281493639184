////////////////////
// SASS VARIABLES
////////////////////////////////////////////////////////////////////////////////

// COLORS
// ---------------------------------------------------------
$primary-50: #E5F2FE
$primary-100: #CCE6FD
$primary-200: #99CDFA
$primary-300: #66B4F8
$primary-400: #339BF5
$primary-500: #0082F3
$primary-600: #0068C2
$primary-700: #004E92
$primary-800: #003461
$primary-900: #001A31

$secondary-50: #D4F9E4
$secondary-100: #BDF3D3
$secondary-200: #8EE7B2
$secondary-300: #5EDB92
$secondary-400: #2FCF71
$secondary-500: #00C350
$secondary-600: #009C40
$secondary-700: #007530
$secondary-800: #004E20
$secondary-900: #002710

$fluo-green-500: #02ECCC

$grey-50: #F1EFEB
$grey-100: #E9E9E9
$grey-200: #DEDEDE
$grey-300: #C0C0C0
$grey-400: #ABABAB
$grey-500: #808080
$grey-600: #484F55
$grey-700: #343A40
$grey-800: #27272a
$grey-900: #282D31

$triadic-100: #07EC66
$triadic-200: #6607EC

/////// USEFULL COLORS
$cyan: #62C5DA
$green: #36D2A1
$red: #FF6363
$yellow: #FFAF57

///// SHARED COLORS
$brand-info: $cyan
$brand-success: $green
$brand-danger: $red
$brand-warning: $yellow

// TRANSITIONS
// ---------------------------------------------------------
$transition-duration-default: 350ms
$transition-timing-default: ease
$transition-default: $transition-duration-default $transition-timing-default
$transition-duration-smooth: 350ms
$transition-timing-smooth: cubic-bezier(.4, 0,.2,1)
$transition-smooth: var(--transition-duration-smooth) var(--transition-timing-smooth)


// TYPOGRAPHY
// ---------------------------------------------------------
$text-font-size: 1rem //-> 16px general font-size apply on body


////////////////////
// CSS CUSTOM PROPERTIES
////////////////////////////////////////////////////////////////////////////////

// https://sass-lang.com/documentation/breaking-changes/css-vars
\:root
  ///// COLORS
  --primary-50: #{$primary-50}
  --primary-100: #{$primary-100}
  --primary-200: #{$primary-200}
  --primary-300: #{$primary-300}
  --primary-400: #{$primary-400}
  --primary-500: #{$primary-500}
  --primary-600: #{$primary-600}
  --primary-700: #{$primary-700}
  --primary-800: #{$primary-800}
  --primary-900: #{$primary-900}
  --secondary-50: #{$secondary-50}
  --secondary-100: #{$secondary-100}
  --secondary-200: #{$secondary-200}
  --secondary-300: #{$secondary-300}
  --secondary-400: #{$secondary-400}
  --secondary-500: #{$secondary-500}
  --secondary-600: #{$secondary-600}
  --secondary-700: #{$secondary-700}
  --secondary-800: #{$secondary-800}
  --secondary-900: #{$secondary-900}
  --fluo-green-500: #{$fluo-green-500}
  --triadic-100: #{$triadic-100}
  --triadic-200: #{$triadic-200}
  --grey-50: #{$grey-50}
  --grey-100: #{$grey-100}
  --grey-200: #{$grey-200}
  --grey-300: #{$grey-300}
  --grey-400: #{$grey-400}
  --grey-500: #{$grey-500}
  --grey-600: #{$grey-600}
  --grey-700: #{$grey-700}
  --grey-800: #{$grey-800}
  --grey-900: #{$grey-900}

  ///// FONTS
  --font-family-sans: Helvetica, Arial, sans-serif
  --font-family-title: Georgia, serif
  --font-family-icon: 'Font Awesome 6 Pro'

  ///// TYPOGRAPHY
  --text-font-size: #{$text-font-size} // -> general font-size apply on body

  ///// ANIMATIONS
  --transition-duration-default: #{$transition-duration-default}
  --transition-timing-default: #{$transition-timing-default}
  --transition-default: #{$transition-duration-default} #{$transition-timing-default}
  --transition-duration-smooth:  #{$transition-duration-smooth}
  --transition-timing-smooth:  #{$transition-timing-smooth}
  --transition-smooth:  #{$transition-duration-smooth}  #{$transition-timing-smooth}


////////////////////
// OVERRIDE VENDORS
////////////////////////////////////////////////////////////////////////////////
$enable-responsive-font-sizes: true
$lg-path-fonts: 'lightgallery/fonts'
$lg-path-images: 'lightgallery/images'
