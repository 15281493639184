////////////////////
// CUSTOM XS
////////////////////////////////////////////////////////////////////////////////
@media (max-width: 440px)

  ///// FLASH MESSAGE
  .flash-message
    left: 0
    right: 0
    width: 100%

    &.show
      .alert
        transform: translateY(0%)
        transition: transform 350ms ease

    .alert
      box-shadow: inherit
      border-radius: 0
      text-align: center
      transition: transform 350ms ease
      transform: translateY(-100%)
