.privacypolicies-com---nb-simple
  max-width: 800px !important

.cc-nb-main-container
  @apply px-8 #{!important}
  @apply py-7 #{!important}
  @apply bg-grey-50 #{!important}
  @apply shadow-lg #{!important}

  .cc-nb-title
    @apply font-sans #{!important}
    @apply text-grey-900 #{!important}
    @apply mb-1 #{!important}
    @apply text-20 #{!important}

  .cc-nb-text
    @apply font-sans #{!important}
    @apply text-grey-600 #{!important}
    @apply text-14 #{!important}
    @apply leading-18 #{!important}

  .cc-nb-okagree
    @apply rounded-full
    @apply bg-transparent #{!important}
    border: 2px solid var(--primary-500) #{!important}
    @apply border-primary-500 #{!important}
    @apply text-primary-500 #{!important}

    &:hover
      @apply bg-primary-500 #{!important}
      @apply text-white #{!important}

  .cc-nb-reject
    @apply rounded-full
    @apply bg-transparent #{!important}
    border: 2px solid var(--primary-500) #{!important}
    @apply border-primary-500 #{!important}
    @apply text-primary-500 #{!important}

    &:hover
      @apply bg-primary-500 #{!important}
      @apply text-white #{!important}

  .cc-nb-changep
    @apply rounded-full
    @apply bg-transparent #{!important}
    border: 2px solid var(--grey-400) #{!important}
    @apply border-grey-400 #{!important}
    @apply text-grey-400 #{!important}

    &:hover
      @apply bg-grey-400 #{!important}
      @apply text-white #{!important}
